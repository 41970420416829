'use client'
import Link from "next/link";
import Headline from "../Headline";
import { motion } from "framer-motion";
import { fadeIn, zoomIn } from "@/utils/Motion";
export const About = () => {

    const services = [
        {
            title: "Shop Space Rents (SSR)",
            description: `The primary service on our platform facilitates
            business owners in showcasing their products and services.`,
            icon: 'icon-store',
            b1: 'bg-accent-light',
            b2: 'bg-accent'
        },
        {
            title: "S2S Discount Service",
            description: `This shop-to-shop discount service, available on the DigitalmalIEx Platform, empowers shop owners to enjoy discounts when purchasing from
            each other.
            `,
            icon: 'icon-discount',
            b1: 'bg-[#CDDFFB]',
            b2: 'bg-[#428AF8]'
        },
        {
            title: "DME Chat",
            description: `Our proprietary chat app connects shop owners for business discussions,
            innovation, training,  and idea exchange.`,
            icon: 'icon-chat',
            b1: 'bg-[#C4F2E3]',
            b2: 'bg-[#10B981]'
        },


        // {
        //     title: "Real Estate",
        //     description: `Through partnerships with reputable real estate firms worldwide, shop owners on our
        //     platform can secure substantial discounts and convenient payment options for landed properties in their preferred locations`,
        //     icon: storeImage as any
        // },
        // {
        //     title: "Hotels Booking",
        //     description: `SCollaborations with hotel reservation services offer our shop owners
        //     discounted rates at partnering hotels.`,
        //     icon: storeImage as any
        // },
        // {
        //     title: "And Much More",
        //     description: `Travel & Tours, Car Hire, Crypto Updates, Training & Education, Crypto Exchange`,
        //     icon: storeImage as any
        // },
    ]

    return (
        <div>
            <section className="dark:bg-jacarta-800 relative py-24">
                <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
                    <img
                        src="/images/gradient_light.jpg"
                        alt="gradient"
                        className="h-full w-full"
                    />
                </picture>
                <div className="container">
                    <Headline text="About Us" subText="A little about Us" />
                    <div className="text-md leading-loose">
                        <motion.p
                            variants={fadeIn("", "", 0.2, 3)}
                            initial='hidden'
                            whileInView='show'
                            viewport={{ once: true }}
                            className="mb-5">
                            Digital Mallex is a decentralized platform facilitating global access
                            to discounted goods and services.
                            At Our DigitalmallEx Platform, we provide a
                            decentralized shopping experience that
                            empowers entrepreneurs and business Owners
                            by offering them an online space to showcase
                            their goods and services. Through our platform,
                            these businesses can effectively increase their
                            digital exposures and reach a broader customer
                            base.
                        </motion.p>
                        {/* <p>
                            By leveraging our decentralized platform, entrepreneurs can access a
                            global marketplace, tapping into new customer segments and expanding
                            their businesses' reach. Additionally, our platform's discount offerings in
                            partnership with real estate markets, hotels, and other services create a
                            competitive edge, enabling businesses to save costs and maximize
                            profitability
                        </p> */}

                    </div>
                    <div className="container mt-16">
                        <div className="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-3">
                            {
                                services.map((s, i) => (
                                    <motion.div
                                        key={`sservice_${i}`}
                                        variants={zoomIn(0.5 * i, 1)}
                                        initial='hidden'
                                        whileInView='show'
                                        viewport={{ once: true }}
                                        className="text-center" >
                                        <div
                                            className={`mb-6 inline-flex rounded-full p-3 ${s.b1}`}
                                        >
                                            <div
                                                className={`inline-flex h-12 w-12 items-center justify-center rounded-full ${s.b2}`}
                                            >
                                                <svg className="h-5 w-5 fill-white">
                                                    <use xlinkHref={`/icons.svg#${s.icon}`}></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <h5 className="font-bold text-lg sm:text-xl dark:text-white mt-5">{s.title}</h5>
                                        <p className="leading-7 mt-4">
                                            {s.description}
                                        </p>
                                    </motion.div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}