export const COOKIE_NAME = 'digimall_token'
export const FAVORITE_SHOP_COOKIE= 'favorite_shops'
export const FAVORITE_PRODUCT_COOKIE= 'favorite_products'
export const annualPercentages = [35, 20, 7, 4, 3, 2, 1, 0.5, 0.25]
export const monthlyPercentages = [25, 10, 6, 4, 3, 2, 1, 1, 1]

export const shopPrefix = `${process.env.NEXT_PUBLIC_API_URL}/user_files/upload/shops/`;
export const productPrefix = `${process.env.NEXT_PUBLIC_API_URL}/user_files/upload/products/`;
export const categoryPrefix = `${process.env.NEXT_PUBLIC_API_URL}/user_files/upload/categories/`;
export const kycPrefix = `${process.env.NEXT_PUBLIC_API_URL}/user_files/upload/kyc/`;


export const defaultLocation = { lat: 3.3084613730113785, lng: 6.543353063295126 }

export const websiteUrl = process.env.NEXT_PUBLIC_WEBSITE_URL

export const maximumProducts = process.env.NEXT_PUBLIC_MAXIMUM_PRODUCTS
export const maximumPremiumProducts = process.env.NEXT_PUBLIC_MAXIMUM_PREMIUM_PRODUCTS

export const monthlyChargeDay = 28