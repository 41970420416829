'use client'
import { fadeIn, textVariant } from "@/utils/Motion";
import { motion } from "framer-motion";

export const NeedHelp = () => {
    return (
        <section className="relative bg-light-base py-24 dark:bg-jacarta-800">
            <div className="container">
                <div className="items-center justify-between lg:flex lg:space-x-24">
                    <div className="lg:w-[45%]">
                        <motion.h2
                            variants={textVariant()}
                            initial="hidden"
                            whileInView="show"
                            viewport={{ once: true }}
                            className="mb-6 font-display text-3xl font-medium text-jacarta-700 dark:text-white">
                            Need help?
                        </motion.h2>
                        <motion.p
                            variants={textVariant()}
                            initial="hidden"
                            whileInView="show"
                            viewport={{ once: true }}
                            className="mb-6 text-lg dark:text-jacarta-300">
                            Discover NFTs, decentralized finance, crypto gaming, and thousands
                            of Dapps! We’ve integrated with the WalletConnect protocol to
                            facilitate your simple and secure connection.
                        </motion.p>
                        <motion.p
                            variants={textVariant()}
                            initial="hidden"
                            whileInView="show"
                            viewport={{ once: true }}
                            className="dark:text-jacarta-300">
                            We’ve integrated with the WalletConnect protocol to facilitate
                            your simple and secure connection to the world of Web3 quickly and
                            instantaneously.
                        </motion.p>
                    </div>
                    <div className="lg:w-[55%]">
                        <div className="mt-12 md:flex md:space-x-8 lg:justify-end">
                            <motion.div
                                initial="hidden"
                                whileInView="show"
                                variants={fadeIn("", "", 0, 0.5)}
                                viewport={{ once: true }}
                                className="relative mb-8 flex-1 self-end rounded-2.5xl bg-green p-8 shadow-2xl">
                                <div className="absolute right-4 top-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        className="h-12 w-12 fill-white/50"
                                    >
                                        <path fill="none" d="M0 0L24 0 24 24 0 24z" />
                                        <path d="M20 2c.552 0 1 .448 1 1v3.757l-2 2V4H5v16h14v-2.758l2-2V21c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V3c0-.552.448-1 1-1h16zm1.778 6.808l1.414 1.414L15.414 18l-1.416-.002.002-1.412 7.778-7.778zM13 12v2H8v-2h5zm3-4v2H8V8h8z" />
                                    </svg>
                                </div>
                                <div>
                                    <span className="mb-2 block font-display text-lg text-white">
                                        Getting Started
                                    </span>
                                    <p className="mb-4 text-white">
                                        Get all information about what you need to
                                        open a shop space and how to go about it
                                    </p>
                                    <a href="#getstarted" className="font-bold text-white underline">
                                        Learn More
                                    </a>
                                </div>
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="show"
                                variants={fadeIn("", "", 0.4, 0.5)}
                                viewport={{ once: true }}
                                className="relative mb-8 flex-1 self-end rounded-2.5xl bg-accent p-8 py-16 shadow-2xl">
                                <div className="absolute right-4 top-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        className="h-12 w-12 fill-white/50"
                                    >
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path d="M22 17.002a6.002 6.002 0 0 1-4.713 5.86l-.638-1.914A4.003 4.003 0 0 0 19.465 19H17a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h2.938a8.001 8.001 0 0 0-15.876 0H7a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-5C2 6.477 6.477 2 12 2s10 4.477 10 10V17.002zM20 17v-4h-3v4h3zM4 13v4h3v-4H4z" />
                                    </svg>
                                </div>
                                <div>
                                    <span className="mb-2 block font-display text-lg text-white">
                                        24/7 Chat Support
                                    </span>
                                    <p className="mb-4 text-white">
                                        Get our friendly customer service agents at your service.
                                    </p>
                                    <a href="#" className="font-bold text-white underline">
                                        Chat Now
                                    </a>
                                </div>
                            </motion.div>
                        </div>
                        {/* End ."mt-12 */}

                        <motion.div
                            variants={fadeIn("", "", 0.8, 0.5)}
                            initial="hidden"
                            whileInView="show"
                            viewport={{ once: true }}
                            className="relative">
                            <div className="relative mx-auto self-start rounded-2.5xl bg-blue p-8 shadow-2xl md:max-w-xs">
                                <div className="absolute right-4 top-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        className="h-12 w-12 fill-white/50"
                                    >
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path d="M5.455 15L1 18.5V3a1 1 0 0 1 1-1h15a1 1 0 0 1 1 1v12H5.455zm-.692-2H16V4H3v10.385L4.763 13zM8 17h10.237L20 18.385V8h1a1 1 0 0 1 1 1v13.5L17.545 19H9a1 1 0 0 1-1-1v-1z" />
                                    </svg>
                                </div>

                                <div>
                                    <span className="mb-2 block font-display text-lg text-white">
                                        Faqs and Quick Info
                                    </span>
                                    <p className="mb-4 text-white">
                                        View FAQs for detailed instructions on specific features.
                                    </p>
                                    <a href="/faqs" className="font-bold text-white underline">
                                        Learn More
                                    </a>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    );
};
