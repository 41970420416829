'use client'
import { motion } from "framer-motion";
import { textVariant } from "../utils/Motion";

type HeadlineProps = {
    text: string,
    subText: string,
    classes?: string
}
const Headline = (props: HeadlineProps) => {
    const { text, classes, subText } = props

    return (
        <motion.div
            variants={textVariant()}
            initial='hidden'
            whileInView='show'
            viewport={{ once: true }}
            className="font-display text-jacarta-700 mb-12 text-center text-lg sm:text-3xl dark:text-white">
            <h5 className="text-accent text-lg mb-3">{text}</h5>
            <h2 className={` ${classes}`}>
                {subText}
            </h2>
        </motion.div>
    );
};

export default Headline;
